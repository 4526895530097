import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosBaseUrl } from "../../utils/axiosBaseUrl";

export const tokenDecode = createAsyncThunk(
  "tokenDecode",
  async (body, { dispatch }) => {
    try {
      const { data } = await axiosBaseUrl.get(`ulr/path`);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  }
);

const initialState = {
  // Query states
  loading: false,
  error: false,

  // User states
  user: {},
  newsPage: {},

  scanning: false,
};

const TajBuildStorageState = createSlice({
  name: "TajBuildStorageState",
  initialState,
  reducers: {
    // User func.
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setNews: (state, action) => {
      state.newsPage = action.payload;
    },
    setScanning: (state, action) => {
      state.scanning = action.payload;
    },
  },
  extraReducers: (builder) => {
    // token decode
    builder.addCase(tokenDecode.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(tokenDecode.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;
      // console.log("user", action.payload);
    });
    builder.addCase(tokenDecode.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const { setUser, setLanguage, setNews,setScanning } = TajBuildStorageState.actions;
export default TajBuildStorageState.reducer;
