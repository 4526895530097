import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import globalState from "../reducers/globalState";
import TajBuildStorageState from "../reducers/TajBuildStorageState";
// import globalRequest from "../reducers/globalRequest";

const rootReducer = combineReducers({
    globalState: globalState,
    TajBuildStorageState: TajBuildStorageState,
    // [globalRequest.reducerPath]: globalRequest.reducer,
})

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['TajBuildStorageState'],
    // blacklist: ['globalState', 'globalRequest'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    })
    // .concat(globalRequest.middleware),
})

export const persistor = persistStore(store)
export default store
