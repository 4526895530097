import axios from "axios";
import { destroyToken, saveToken } from "./Token";

const axiosBaseUrl = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const axiosBaseRequest = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosBaseRequest.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
  return config;
});

axiosBaseRequest.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosBaseRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const { config, response } = error;
    const originalRequest = config;

    if (
      response?.status === 401 ||
      response?.status === 403
      // || error.includes('401')
    ) {
      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        destroyToken();
        window.location.href = '/login'
        return Promise.reject(error);
      }
      // make a request to refresh token and update the headers
      try {
        console.log("refreshToken", refreshToken);
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}refresh_token`,
          {
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          }
        );
        saveToken(data.jwt_token, data.refresh_token);
        originalRequest.headers.Authorization = `Bearer ${data.jwt_token}`;
        return axios(originalRequest);
      } catch (err) {
        destroyToken();
        return Promise.reject(err);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosBaseUrl, axiosBaseRequest };
