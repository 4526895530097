import { Suspense, lazy } from "react";
import Loader from "../components/loader/Loader";
import { Route, Routes } from "react-router-dom";

const Layout = lazy(() => import("../pages/layout/Layout"));

const Main = lazy(() => import("../pages/main/Main"));
const Exhibition = lazy(() => import("../pages/exhibition/Exhibition"));
const FotoGallery = lazy(() => import("../pages/gallery/FotoGallery"));
const VideoGallery = lazy(() => import("../pages/gallery/VideoGallery"));
const News = lazy(() => import("../pages/news/News"));
const Media = lazy(() => import("../pages/media/Media"));
const NewsPage = lazy(() => import("../pages/news/NewsPage"));
const QRCode = lazy(() => import("../pages/qrcode/QRCode"));
const Contact = lazy(() => import("../pages/contact/Contact"));
const PrivacyPolicy = lazy(() => import("../pages/documents/PrivacyPolicy"));
const UserAgreement = lazy(() => import("../pages/documents/UserAgreement"));

const Admin = lazy(() => import("../pages/admin/Admin"));
const AdminNews = lazy(() => import("../pages/admin/AdminNews"));
const AdminAddNews = lazy(() => import("../pages/admin/AdminAddNews"));
const AdminApplicationStand = lazy(() =>
  import("../pages/admin/AdminApplicationStand")
);
const AdminFotoGallery = lazy(() => import("../pages/admin/AdminFotoGallery"));
const AdminVideoGallery = lazy(() =>
  import("../pages/admin/AdminVideoGallery")
);
const AdminMedia = lazy(() => import("../pages/admin/AdminMedia"));
const AdminAddMedia = lazy(() => import("../pages/admin/AdminAddMedia"));
const AdminMain = lazy(() => import("../pages/admin/AdminMain"));
const AdminAddMain = lazy(() => import("../pages/admin/AdminAddMain"));
const AdminExhibination = lazy(() =>
  import("../pages/admin/AdminExhibination")
);
const AdminAddExhibination = lazy(() =>
  import("../pages/admin/AdminAddExhibination")
);
const AdminPartners = lazy(() => import("../pages/admin/AdminPartners"));
const AdminParticipants = lazy(() =>
  import("../pages/admin/AdminParticipants")
);
const AdminAddParticipants = lazy(() =>
  import("../pages/admin/AdminAddParticipants")
);
const AdminQRCode = lazy(() => import("../pages/admin/AdminQRCode"));
const AdminSendMessage = lazy(() => import("../pages/admin/AdminSendMessage"));

const Login = lazy(() => import("../pages/login/Login"));
const Page404 = lazy(() => import("../pages/other/Page404"));

const suspense = (htmlElem) => (
  <Suspense fallback={<Loader />}>{htmlElem}</Suspense>
);

const RoutesCom = () => {
  return (
    <Routes>
      <Route path="/" element={suspense(<Layout />)}>
        <Route path="/contact" element={suspense(<Contact />)} />
        <Route path="/exhibition/:id" element={suspense(<Exhibition />)} />
        <Route path="/foto_gallery/:id" element={suspense(<FotoGallery />)} />
        <Route path="/video_gallery/:id" element={suspense(<VideoGallery />)} />
        <Route path="/news" element={suspense(<News />)} />
        <Route path="/media" element={suspense(<Media />)} />
        <Route path="/news_page" element={suspense(<NewsPage />)} />
        <Route path="/qrcode" element={suspense(<QRCode />)} />
        <Route path="/privacy_policy" element={suspense(<PrivacyPolicy />)} />
        <Route path="/user_agreement" element={suspense(<UserAgreement />)} />
        <Route index element={suspense(<Main />)} />
      </Route>
      <Route path="/login" element={suspense(<Login />)} />
      <Route path="/admin" element={suspense(<Admin />)}>
        <Route index element={suspense(<AdminNews />)} />
        <Route path="/admin/add_news" element={suspense(<AdminAddNews />)} />
        <Route
          path="/admin/exhibition"
          element={suspense(<AdminExhibination />)}
        />
        <Route
          path="/admin/add_exhibition"
          element={suspense(<AdminAddExhibination />)}
        />
        <Route
          path="/admin/foto_gallery"
          element={suspense(<AdminFotoGallery />)}
        />
        <Route
          path="/admin/participants"
          element={suspense(<AdminParticipants />)}
        />
        <Route
          path="/admin/add_participants"
          element={suspense(<AdminAddParticipants />)}
        />
        <Route path="/admin/partners" element={suspense(<AdminPartners />)} />
        <Route
          path="/admin/video_gallery"
          element={suspense(<AdminVideoGallery />)}
        />
        <Route path="/admin/media" element={suspense(<AdminMedia />)} />
        <Route path="/admin/add_media" element={suspense(<AdminAddMedia />)} />
        <Route path="/admin/main" element={suspense(<AdminMain />)} />
        <Route path="/admin/add_main" element={suspense(<AdminAddMain />)} />
        <Route
          path="/admin/applications_stand"
          element={suspense(<AdminApplicationStand />)}
        />
        <Route path="/admin/qrcode" element={suspense(<AdminQRCode />)} />
        <Route
          path="/admin/send_message"
          element={suspense(<AdminSendMessage />)}
        />
      </Route>
      <Route path="*" element={suspense(<Page404 />)} />
    </Routes>
  );
};

export default RoutesCom;
