import RoutesCom from "./routes/RoutesCom";
import "./App.css";
import "./fonts/gilroy/stylesheet.css";
import "./styles/fluentStyle.css";
import "./styles/testStyle.css";
import "./styles/fontStyle.css";
import "./styles/style.css";
import "./styles/setAntdStyle.css";
import "./styles/defaultTegsStyle.css";

function App() {
  return (
    <div className="App">
      <RoutesCom />
    </div>
  );
}

export default App;
